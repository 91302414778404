.App {
  background-color: #000000;
  color: #d9d9d9;
  
}
body{
  background-color: #000000;

}


body::-webkit-scrollbar {
  width: 15px;

}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.937);
}

body::-webkit-scrollbar-thumb {
  background-color: rgba(27, 27, 27, 0.825);
  border-radius: 3px;
  border: 2px solid  #1acc52;
   
}

body::-webkit-scrollbar-button{
  background-color: rgba(12, 12, 12, 0.911);
  border-radius: 3px;
  border: 2px solid  #1acc52;

}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  height: 15px;
  width: 15px;
  background-position: center 4px;
  background: url("/icons/caret-up-fill.svg");

}

::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  height: 16px;
  width: 16px;
  background-color: #152d05;

}

::-webkit-scrollbar-button:single-button:vertical:increment {
  height: 15px;
  width: 15px;
  background-position: center 4px;
  background: url("/icons/caret-down-fill.svg");

}


::-webkit-scrollbar-button:single-button:vertical:increment:active {
  height: 16px;
  width: 16px;
  background-color: #152d05;
}

.card5{
  display: inline-block;
}


hr{
  color: rgba(88, 189, 15, 0.711);
}


@font-face {
  font-family: "Road Rage";
  src: url("../Road_Rage/RoadRage-Regular.ttf");
}

@font-face {
  font-family: "Ysabeau Office";
  src: url("../Ysabeau_Office/static/YsabeauOffice-Regular.ttf");
}



.p{
    font-family: "Ysabeau Office", sans-serif;
}

.profileBG{
 
  display: grid;
  gap: 10px;
  width: 100%;
background: rgb(0,42,13);
background: linear-gradient(0deg, rgba(0,42,13,1) 0%, rgba(0,0,0,1) 100%);
backdrop-filter: blur(16px) saturate(180%);
-webkit-backdrop-filter: blur(16px) saturate(180%);
-moz-backdrop-filter: blur(16px) saturate(180%);
padding: 10px;  
filter: drop-shadow(0 30px 10px rgba(0,0,0,0.125));
}

.profile{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows:min-content min-content;
}

.profileR{ 
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows:min-content;
}





.about1{
  text-align: center;
  padding: 15px;
}

.about2{
  background-image: url("./city.jpg");
  background-size: cover;
  width: 100%;
  position:"relative";
}

.PageT{

    animation: move 1.3s;
  display: inline-block;
  position: relative;
  margin: 0 0 10px 0;
}

.PageT2{

  animation: move 1.2s;
position: relative;
margin: 0 0 5px 0;
background-repeat: no-repeat;
background-size: cover;
background-image: url('./main2.png');


}

.PageT3{

  animation: move 1.2s;
display: inline-block;
position: relative;
margin: 0 0 5px 0;
}



 @keyframes move {
   100% {
    left: 0;
   }
   0% {
 
     left: 100%;
   }
 }



.intro {
    font-family: "Ysabeau Office", sans-serif;
  font-size:larger;
  margin-bottom: 5rem;
  text-align: center;
}

.intro2 {
  font-size:larger;
  margin-bottom: 5rem;
  text-align: center;
  height: 100%;
  width: 100%;

}

.About{
  display: block; 
  background-color: #000000;
}



.image3{

width: 100%;
height: 100vh;
}



.image11{
  display: none;
}

.modal{
  background-color: rgba(0, 255, 17, 0.026);
}



.webplans{
  color: #1eee60;
  margin-top: 8rem;
  margin-bottom: 3rem;
}

.webservices1{
  color: #1eee60;
  font-size: 40px;
  margin-bottom: 1px;
}

.glow1{
    -moz-animation: blink 2s infinite alternate;
    -webkit-animation: blink 2s infinite alternate;
    animation: blink 2s infinite alternate;
      font-family: "Ysabeau Office", sans-serif;
}



.navbar{

  display: flex;
  padding: 2px;
  margin-bottom: 0px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.19);
  border-radius: 3px;
  box-shadow: 0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
  -webkit-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
  -moz-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);

}



a:link, a:visited{
  font-family: "Road Rage", sans-serif;
  font-size: 28px;
  font-style: normal;

color: #8cf370;
text-decoration: none;
text-shadow: 0 0 0.125em hsla(104, 25%, 88%, 0.3), 0 0 0.45em;
}


a:hover{
  color: #d3ffe1;
  cursor:crosshair;
}


label{
  font-size: 18px;
}

.PhoneInput{
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
  border-radius: 3px;
}

input[type="checkbox"] {
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

input[type="checkbox"]:checked{
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
  color: #000000;
}

input[type="text"] {
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

input[type="number"] {
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

input[type="email"] {
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

input[type="password"] {
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

textarea[type="text"]{
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

select[id="State"]{
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

select[id="pages"]{
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

select[id="listings"]{
  background-color : rgba(185, 184, 184, 0.711);
  border: 1px solid #1eee60;
}

.img2 {
  display: block;
  float: left;
  background-color: rgba(17, 17, 17, 0.496);
  box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee606d;
  -webkit-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee6064;
  -moz-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee6052;
  margin:1px;
  padding: 1px;
  text-align: left;
  width: 40%;
  opacity: 0.93;
  border: 1px solid #3bb92a;
  border-radius: 3px;
}

.img3 {
  display: block;
  background-color: rgba(17, 17, 17, 0.496);
  margin:1px;
  padding: 1px;
  margin-bottom: 10px;
  text-align: left;
  width: 32rem;
  height: 22rem;
  opacity: 0.93;
  border: 1px solid #1eee6082;
  border-radius: 3px;
}

.div2{

  border-radius: 3px;
  box-shadow: 0 4px 8px 0 rgba(164, 255, 53, 0.2), 0 6px 15px 0 rgba(164, 255, 53, 0.19);
  -webkit-box-shadow:  0 4px 8px 0 rgba(164, 255, 53, 0.2), 0 6px 15px 0 rgba(164, 255, 53, 0.19);
  -moz-box-shadow:  0 4px 8px 0 rgba(164, 255, 53, 0.2), 0 6px 15px 0 rgba(164, 255, 53, 0.19);
  margin-left: 15px;
  margin-right: 15px;
  padding: 15px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 95%;
  height: fit-content;
  margin: 20px;
  border-radius: 3px;
  margin-bottom: 5rem;
  background-repeat: no-repeat;
  background-position: center;
}



.home2{

  margin-bottom: 10rem;
    font-size: 20px;


}


.card1 {
  display: inline-block;
  border-radius: 3px;
  padding: 18px;
  margin: 20px;
  background-color:  rgba(32, 32, 32, 0.492);  
  -moz-box-shadow: rgba(32, 32, 32, 0.492);
  -webkit-box-shadow: rgba(32, 32, 32, 0.492);
  box-shadow:  1px 1px 1px rgb(255, 255, 255), 0 0 7px #1eee60, 0 0 10px #1eee60;
  width: 30%;
  text-align: left;
  
}

.cards5{
  display: inline-block;
  justify-content: center;
  flex-wrap: wrap;
  margin: 30px;
  padding: 3px;
  background-color: #000000;
  border-radius: 3px;
  margin-bottom: 5rem;
}  


.card2{
    font-family: "Ysabeau Office", sans-serif;
  border-radius: 3px;
  margin: 8px;
  padding: 10px;
}

.card2:hover{
  background-color:  rgba(32, 32, 32, 0.504);
}

.table{
  background-color: transparent;
}


.box{

  border-radius: 3px;
  background-color: rgba(32, 32, 32, 0.492);
  box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee608a;
  -webkit-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee607a;
  -moz-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60bb;
  margin-left: 60px;
  margin-right: 60px;
  padding: 20px;
    font-family: "Ysabeau Office", sans-serif;
  font-weight: 500;
  border: 1px solid #1eee60d4;
}

td{
  color:#999999;

}

th{
  color: #656565;
}



.box0{
  background-color: #00000054;
  border-radius: 3px;
  box-shadow: 1px 2px 4px 1px rgba(58, 180, 25, 0.1), 1px 3px 6px 2px #1eee606e;
  -webkit-box-shadow: 1px 2px 4px 1px rgba(58, 180, 25, 0.1), 1px 3px 6px 2px #1eee6056;
  -moz-box-shadow: 1px 2px 4px 1px rgba(58, 180, 25, 0.1), 1px 3px 6px 2px #1eee604b;
  margin-left: 60px;
  margin-right: 60px;
  padding: 20px;
}

.box1{
  text-align: left;
  display: inline-block;
  border-radius: 3px;
  box-shadow: 0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
  -webkit-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
  -moz-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
  margin-left: 60px;
  margin-right: 60px;
  margin-bottom: 2rem;
  padding: 20px;
  background-color: rgba(32, 32, 32, 0.492);
  width: 60%;
}

.box2{
  display: block;
  float: right;
  background-color: rgba(17, 17, 17, 0.905);
  box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee608a;
  -webkit-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee607a;
  -moz-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60bb;
  width: 40%;
  margin: 20px;
  padding: 10px;
  border-radius: 4px;
  
}

.box3{
  box-shadow: 4px 4px 8px 6px rgba(248, 248, 248, 0.2), 4px 6px 15px 6px rgba(246, 246, 244, 0.19);
  -webkit-box-shadow:  4px 4px 8px 6px rgba(248, 248, 248, 0.2), 4px 6px 15px 6px rgba(246, 246, 244, 0.19);
  -moz-box-shadow:  4px 4px 8px 6px rgba(248, 248, 248, 0.2), 4px 6px 15px 6px rgba(246, 246, 244, 0.19);
  color: #afafaf;
  display: block;
  border-radius: 3px;
  background-color: rgba(17, 17, 17, 0.914);
  margin-left: 60px;
  margin-right: 60px;
  padding: 20px;
  text-align: left;
  border: 1px solid #3bb92a;
  margin-bottom: 3rem;
}

.box4{
  color: #afafaf;
  display: block;
  float: right;
  border-radius: 3px;
  box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee606d;
  -webkit-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee6064;
  -moz-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee6052;
  background-color: rgba(17, 17, 17, 0.914);
  width: 40%;
  margin: 20px;
  padding: 10px;
  margin-left: 0px;
  margin-right: 10px;
  text-align: left;
  border: 1px solid #3bb92a;
}


.box5{
  border-radius: 3px;
  box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee608a;
  -webkit-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee607a;
  -moz-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60bb;
  margin-left: 50px;
  margin-right: 50px;
  padding: 20px;
  background-color: rgba(32, 32, 32, 0.492);
  width: 30%;
  text-align: left;
}

.box6{
  color: #b4b4b4;
  display: inline-block;
  border-radius: 6px;
  background-color: rgba(10, 10, 10, 0.177);
  margin: 5px;
  padding: 20px;
  text-align: left;
  font-size: 20px;
  font-family: "Ysabeau Office";
}

.box7{
  color: #1acc52;
  text-align: right;
  display: flex;
  border-radius: 4px;
  border: 1px solid #2a2e29;
  background-color: rgba(32, 32, 32, 0.492);
  padding: 0px;
  a:link{
    font-size: 16px;
    font-family: "Ysabeau Office", sans-serif;
  }
}

.box8{
  display: block;
  border-radius: 4px;
  border: 1px solid #2a2e29;
  background-color: rgba(23, 23, 23, 0.51);
  padding: 0px;
}


.box9{
  text-align: right;
  display: flex;
  border-radius: 4px;
  height: fit-content;
  width: fit-content;
  border: 1px solid #2a2e29;
  background-color: rgba(32, 32, 32, 0.492);
  padding: 0px;
  font-size: 12px;
}

.row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 30px;
}

.column{
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.NT{
  position: absolute;
  right: 0.5rem;
  top: 150px;

}


.WEB{
  font-size: 50px;
  font-family: "Road Rage", serif;
  position: absolute;
  background: -webkit-linear-gradient(#dddddd, #3bb92a, #292929);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 4rem;
  top: 150px;
}




.container .card{
    font-family: "Ysabeau Office", sans-serif;
  font-weight: 500;
  background-color: #000000;
  align-items: center;
  color: rgba(255, 255, 255, 0.621);
}


.container .card .iconP {

  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  border-radius: 3px;
  box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60a9;
  -webkit-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60ab;
  -moz-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee6096;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 6rem;
  width: 25rem;
  height: 20rem;
  background-color: #111;
  border: 1px solid #3bb92a;

}

.container .card .iconB {

  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  border-radius: 3px;
  box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60a9;
  -webkit-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60ab;
  -moz-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee6096;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 6rem;
  width: 25rem;
  height: 20rem;
  background-color: #111;
  border: 1px solid #3bb92a;

}


.container .card .iconE {

  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  border-radius: 3px;
  box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60a9;
  -webkit-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee60ab;
  -moz-box-shadow: 1px 2px 6px 1px rgba(58, 180, 25, 0.1), 1px 3px 12px 4px #1eee6096;
  margin-left: 0px;
  margin-right: 0px;
  padding-top: 6rem;
  width: 25rem;
  height: 20rem;
  background-color: #111;
  border: 1px solid #3bb92a;

}



.container .card .slide {
    width: 25rem;
    height: 16rem;
    transition: 0.5s;
    background-color: #111;


}

.container .card .slide.slide1 {

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transition: .7s;
    transform: translateY(100px);


}

.container .card:hover .slide.slide1{

    transform: translateY(0px); 

}


.container .card .slide.slide2 {

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    transition: .8s;
    padding: 0.5rem;
    border: 1px solid #3bb92a;
    transform: translateY(-100px);
    font-size: 18px;
    border-radius: 3px;
}


.container .card:hover .slide.slide2{

    transform: translateY(0);
}

.container .card:hover {

  
.iconP {
  background-image: url("./portfoliored.jpg");
background-size: cover;
border: 1px solid #3bb92a;

}

.iconB {
  background-image: url("./business.jpg");
background-size: cover;
border: 1px solid #3bb92a;
}

.iconE {
  background-image: url("./shop.jpg");
background-size: cover;
border: 1px solid #3bb92a;
}
.icon{
  display: none;
}

}




.container .card .slide.slide2::after{

    content: "";
    position: absolute;
    left: 50%;
    left: 50%;
    transform: translateX(-50%);

}



.container .card .slide.slide2 .content p {

    margin: 0px;
    padding-top: 2px;
    text-align: center;
    
}



.div1:hover{
  margin-top: 20px;
}

.div1{
  font-size: 19px;

}

.glow {
  text-align: left;
  border-radius: 5px;
  margin: 20px;
  padding: 15px;
  border: none;
  outline: none;
  color: #bfbfbf;
  -moz-box-shadow: 1px 1px 1px rgb(58, 180, 25), 1px 2px 7px #1eee60, 1px 2px 7px #1eee60;
  -webkit-box-shadow: 1px 1px 1px rgb(58, 180, 25), 1px 2px 7px #1eee60, 1px 2px 7px #1eee60;
  background-color:  1px 1px 1px rgb(58, 180, 25), 1px 2px 7px #1eee60, 1px 2px 7px #1eee60;
  box-shadow:  1px 1px 1px rgb(58, 180, 25), 1px 2px 7px #1eee60, 1px 2px 7px #1eee60;
  position: relative;
  z-index: 0;
  background-color: #111111c7;
 
}

.glow:before {
  content: '';
  box-shadow:
  inset 10px 0 20px rgba(0, 255, 17, 0.082),
  inset -10px 0 20px rgba(0, 255, 81, 0.226),
  inset 10px 0 30px rgba(100, 210, 70, 0.199),
  inset -10px 0 30px rgba(94, 207, 53, 0.164),
  0 0 10px #a8f04a,
  -5px 0 10px rgb(53, 222, 41),
  5px 0 10px rgb(58, 250, 55);
  position: absolute;
  top: -2px;
  left:-2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glow 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}



.glow:active {
  color: #ffffff;

}

.glow:active:after {
  background: transparent;  

}

.glow:hover:before {
  opacity: 0.5;

}

.glow:after {
  
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  background: #111;
  left: 0;
  top: 25px;
  border-radius: 10px; 
  opacity: 1;

}

h2{
  margin-bottom: 22px;
  -moz-animation: blink 2s infinite alternate;
  -webkit-animation: blink 2s infinite alternate;
  animation: blink 2s infinite alternate;
  font-family: "Road Rage", serif;
}


h3 {

  font-family: "Road Rage", serif;

}

.Lfont{
  font-family: "Road Rage", serif;
  font-size: 30px;
}

h4{
  font-family: "Road Rage", serif;
}

h5{
  font-family: "Road Rage", serif;
  color: #1eee60;
}


@keyframes blink {
    
  0%, 18%, 22%, 25%, 53%, 57%, 100% {

      text-shadow:
      0 0 4px #152d05,
      0 0 8px #75ea00,
      0 0 10px #75ea00b0,
      0 0 15px rgba(141, 204, 64, 0.3),
      0 0 19px rgba(164, 255, 53, 0.155),
      0 0 25px rgba(164, 255, 53, 0.121),
      0 0 30px rgb(164, 255, 53),
      0 0 30px rgb(120, 230, 41);
  
  }
  
  20%, 24%, 55% {        
      text-shadow: none;
  }    
}



.cards1 {
  font-family: "Ysabeau Office", sans-serif;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 15px;
  padding: 3px;
  border-radius: 3px;
  margin-bottom: 5rem;

}

.cards0 {
  display: flex



}



.cards2{
  display: flex; 
  background-color: #000000;
  font-family: "Ysabeau Office", sans-serif;
  font-weight: 500;
  font-size: 20px;

}

.cards3{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  background-color: #000000;
  font-family: "Ysabeau Office", sans-serif;
  font-weight: 500;
  justify-content: center;
  font-size: 20px;
}



.card-footer {
  text-align: center;

}

button[type="submit"]{
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

button[type="button"]{
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.iconfooter{
  margin-top: 3px;
  padding: 5px 10px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.iconfooter:hover{
  padding: 5px 10px;
  -moz-animation: none;
  -webkit-animation: none;
  animation:none;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.button2 {

  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;

}

button {
  border:#8fb099 solid 1px;
  font-size: medium;
  color: #b5b5b5;
  padding: 10px 30px;
  background: black;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  text-decoration: none;
  -webkit-box-shadow:  0 5px 15px rgba(87, 232, 34, 0.332);
  -moz-box-shadow:  0 5px 15px rgba(87, 232, 34, 0.332);
  box-shadow: 0 5px 15px rgba(87, 232, 34, 0.332);
}

button:hover {
  padding: 10px 30px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  cursor: crosshair;
  color: #ececec;
  border: #1acc52 solid 1px;
  -moz-animation: shakes 0.2s infinite alternate;
  -webkit-animation: shakes 0.2s infinite alternate;
  animation: shakes 0.2s infinite alternate;
  box-shadow: 0px 1px 6px #1eee60;

}

.Tab:active[type="button"]{
  border-style: solid;
  -webkit-border-style: solid;
  -moz-border-style: solid;
  border-width: 3px;
  -moz-border-width: 3px;
  -webkit-border-width: 3px;
  -moz-border-color: #9bf4b7;
  border-color: #9bf4b7;
  font-size: medium;
  border-radius: 3px;
}

.Tab[type="button"]{
  border-radius: 3px;
  font-family: "Ysabeau Office";
}

.Tabs{
  -ms-overflow-style: none; 
  scrollbar-width: none; 
overflow-x: hidden;
font-family: "Ysabeau Office";
}

.Tabs ::-webkit-scrollbar{
    width: 0px;
    background: transparent; 

}

button:active{
  border-style: solid;
  -webkit-border-style: solid;
  -moz-border-style: solid;
  border-width: 2px;
  -moz-border-width: 2px;
  -webkit-border-width: 2px;
  -moz-border-color: #9bf4b7;
  border-color: #9bf4b7;
}

.button2{
  width:99%;
  color: #1eee60;
  font-size:20px;
}



@keyframes shakes {
  0% { transform: translateX(0) }
  0% { -moz-transform: translateX(0) }
  0% { -webkit-transform: translateX(0) }
  25% { transform: translateX(5px) }
  25% { -webkit-transform: translateX(5px) }
  25% { -moz-transform: translateX(5px) }
  50% { transform: translateX(-5px) }
  50% { -moz-transform: translateX(-5px) }
  50% { -webkit-transform: translateX(-5px) }
  75% { transform: translateX(5px) }
  75% { -moz-transform: translateX(5px) }
  75% { -webkit-transform: translateX(5px) }
  100% { transform: translateX(0) }
  100% { -moz-transform: translateX(0) }
  100% { -webkit-transform: translateX(0) }
 }


 .tablet{
  display: block;
   margin-top: 5rem;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   background-image: url('./cyberpunk.jpg');
   border: 1px solid #3bb92a;
 }

 .mobile{
  display: none;
 }



@media only screen and (max-width: 912px) {

  .App {
    background-color: #000000;
    color: #afafaf;
    
  }

  .about2{
    background-image:none;
  }


  .navbar{
    background-image: none;
  }
  

  .profileBG{
 
    display: grid;
    width: fit-content;
    align-content: center;

  }


  .profile{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  
  .profileR{ 
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }


  .card1{
width: 45%;
  }

  .PageT2{

  background-size: contain;
  
  }

  .NT{
    right: 0.1rem;
    top: 150px;
  
  }
  
  
  .WEB{
    font-size: 35px;
    left: 2rem;
    top: 10px;
  }
  


  button:active {
    animation: none;
    
    }
    
    button:active:after {
     animation: none;
    
    }
   
    
.image11{
  display: none;
}
  
    button:hover {
      text-shadow: 0 10px 20px rgba(168, 255, 38, 0.611);
      color: #ececec;
      -moz-animation: none;
      -webkit-animation: none;
      animation: none;

    
    }



.div2{

  margin: 10px;
  padding: 30px;
  border-radius: 3px;
  margin-bottom: 2rem;
  background-image:none;
  width: fit-content;
  height: fit-content;
}

.box{
  margin-bottom: 2rem;
}


.box5{
  margin-left: 20px;
  margin-right: 20px;
  padding: 15px;
  width: 70%;
}

.tablet{
  display: block;
   margin-top: 4rem;
   background-image:none;
   border: none;
 }

 .mobile{
  display: none;
 }


}

@media only screen and (max-width: 1080px) {

.img3 {

  width: 31rem;
  height: 18rem;

}
}


@media only screen and (max-width: 1024px) {

  .NT{
    display: block;
  }
  
  .WEB{
    display: block;
  }


  .webservices{
    display: none;
  }

  button:hover {
    text-shadow: 0 10px 20px rgba(168, 255, 38, 0.611);
    color: #ececec;
    -moz-animation: none;
    -webkit-animation: none;
    animation: none;

  }

  .img3 {
    display: block;
    background-color: rgba(17, 17, 17, 0.496);
    margin: 1px;
    padding: 1px;
    margin-bottom: 6px;
    text-align: left;
    width: 30rem;
    height: 17rem;
    border: 1px solid #929292d4;
  }


  .home2{
    text-align: center;
  }


  .box4{
    border-radius: 3px;
    box-shadow: 0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    -webkit-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    -moz-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    margin-left: 220px;
    margin-right: 220px;
    padding: 25px;
    background-color: rgba(32, 32, 32, 0.492);
    width: 56%;
  }
  .box1{
    width: 90%;
    margin-left: 10px;
    margin-right: 10px;
  }

  .cards1 {
    display: inline-block;
    justify-content: center;
    flex-wrap: wrap;
    margin: 30px;
    padding: 3px;
    background-color: #000000;
    border-radius: 3px;
    margin-bottom: 5rem;

  }  

  .cards5 {
    display: grid;
    justify-content: center;
    flex-wrap: nowrap;
    margin: 10px;
    padding: 3px;
    background-color: #000000;
    border-radius: 3px;
    margin-bottom: 5rem;
  }  


  .div2{
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(164, 255, 53, 0.2), 0 6px 15px 0 rgba(164, 255, 53, 0.19);
    -webkit-box-shadow:  0 4px 8px 0 rgba(164, 255, 53, 0.2), 0 6px 15px 0 rgba(164, 255, 53, 0.19);
    -moz-box-shadow:  0 4px 8px 0 rgba(164, 255, 53, 0.2), 0 6px 15px 0 rgba(164, 255, 53, 0.19);
    margin-left: 10px;
    margin-right: 10px;
    padding: 15px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 5px;
    padding: 10px;
    border-radius: 3px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    background-image:none;
    height: fit-content;
    width: fit-content;
  }

  .box2{
    width: 60%;
  }


.cards2{
  display: grid;
}



.image11{
  display: none;
}

.boxE{
  margin-top: 15px;
}

.boxP{
  margin-top: 15px;
}

.boxB{
  margin-top: 15px;
}

  
   .mobile{
    display: none;
   }

   .tablet{
    display: block;
    margin-bottom: 2rem;
    margin-top: 4rem;
   }

}


 @media only screen and (max-width: 820px) {

.cards1{
  background-image: none;
}


  .card1{
    width: 40%;
    padding: 10px;
  }


  .box{
    margin-left: 10px;
    margin-right: 10px;
    padding: 10px;
    width: 98%;
  }

  .image11{
    display: none;
  }

  .div2{
    border-radius: 3px;
    box-shadow: none;
    -webkit-box-shadow:  0;
    -moz-box-shadow:  0;
    margin-left: 8px;
    margin-right: 8px;
    padding: 10px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin: 10px;
    padding: 20px;
    border-radius: 3px;
    margin-bottom: 1rem;
    background-image: none;
    width: fit-content;
    height: fit-content;
  }


  .div1:hover{
    margin-top: 8px;
  }
  
  .div1{
    margin-bottom: 20px;
  }

  .cards2{
    display: block; 
  }




  .intro{ 
  width:94%;
  }

  .intro2 {
    margin-bottom: 10px;
  }

  .img3{
    width: 29.5rem;
    height: 16.5rem;
  }


  .box1{
    border-radius: 3px;
    box-shadow: 0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    -webkit-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    -moz-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    margin-left: 10px;
    margin-right: 10px;
    padding: 20px;
    background-color: rgba(32, 32, 32, 0.492);
    width: 90%;
  }

  .box2{
    display: block;
    width: 40%;
    margin: 2px;
    padding: 2px;
        width: 60%;
  }

  .box3{
    margin-bottom: 40px;
  }

  .box4{
    margin-right: 160px;
    margin-left: 160px;
    width: 60%;
  }

  button:active {
  animation: none;
  
  }
  
  button:active:after {
   animation: none;
  
  }

  .glow {
    margin-bottom: 3rem;
    padding: 15px;
  }

 

  button:hover {
    text-shadow: 0 10px 20px rgba(168, 255, 38, 0.611);
    padding: 10px 30px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    font-size: medium;
    cursor: crosshair;
    color: #ececec;
    -moz-animation: none;
    -webkit-animation: none;
    animation: none;
    box-shadow: 0px 1px 6px #1eee60;
    -webkit-box-shadow: 0px 1px 6px #1eee60;
    -moz-box-shadow: 0px 1px 6px #1eee60;
  
  }

  .container .card .slide {
    width: 24.9rem;

}


  
   .mobile{
    display: none;
   }

   .tablet{
    display: block;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
   }

 }

 @media only screen and (max-width: 431px) {

  .navbarhome{
    position: relative; 
    z-index: 0;
  }


  .card5{

width: 99%;
  }



  .webplans{
    color: #1eee60;
    margin-top: 4rem;
    margin-bottom: 1rem;
  }

  .box5{
    border-radius: 3px;
    margin-left: 1px;
    margin-right: 1px;
    padding: 3px;
    background-color: rgba(32, 32, 32, 0.492);
    width: 99%;
  }

  .PageT{

    animation: move 1.2s;
    display: inline-block;
    position: relative;
    margin: 0;
    width: 100%;
}

.PageT2{
display: none;
position: relative;
margin: 0 0 5px 0;
}

  .image11{
    display: block;
  }

  .div2{
    border-radius: 3px;
    box-shadow: none;
    -webkit-box-shadow:  0;
    -moz-box-shadow:  0;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    margin-bottom: 0.5rem;
    background-image: none;
    width: fit-content;
    height: fit-content;
  }

  .glow {
    margin: 0px;
    padding: 5px;
    width: 100%;
    margin-bottom: 3rem;

  }


  .cards1{
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 1rem;
    padding: 5px;
    
  }

  .cards2{
    display:block; 
    margin: 4px;
  }

  .cards3{
    margin: 2px;
    margin-top: 5px;
    margin-bottom: 1rem;
    padding: 4px;
    width: 99%;
  }

  .card1{
    padding: 10px;
    width: 97%;
    padding: 10px;
    margin-left: 3px;
    margin-right: 3px;
  }

  .box{
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 2rem;
    padding: 15px;
    width: 99%;
    text-align: left;
  }

  .img2{
    width: 99%;
    margin-bottom: 20px;
  }

  .img1{
    width: 98%;
  }

  .box1{
    border-radius: 3px;
    box-shadow: 0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    -webkit-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    -moz-box-shadow:  0 4px 8px 4px rgba(168, 167, 167, 0.2), 0 6px 15px 4px rgba(145, 145, 144, 0.19);
    margin-left: 20px;
    margin-right: 20px;
    padding: 15px;
    background-color: rgba(32, 32, 32, 0.492);
    width: 90%;
  }


.box2{
  width: 100%;
  margin: 3px;
  padding: 3px;
}

.box3{

  border-radius: 3px;
  background-color: rgba(17, 17, 17, 0.914);
  margin-left: 1px;
  margin-right: 1px;
  padding: 4px;
  border: 1px solid #3bb92a;
}

.box4{
  margin-left: 0px;
  margin-right: 0px;
  width: 100%;
}

.box6{
  margin: 1px;
  padding: 4px;
}

.intro2 {
  margin-bottom: 0.5rem;
}

button:hover {
  text-shadow: 0 10px 20px rgba(168, 255, 38, 0.611);
  padding: 10px 30px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: medium;
  cursor: crosshair;
  color: #ececec;
  -moz-animation: none;
  -webkit-animation: none;
  animation: none;
  box-shadow: 0px 1px 6px #1eee60;
  -webkit-box-shadow: 0px 1px 6px #1eee60;
  -moz-box-shadow: 0px 1px 6px #1eee60;

}

.container .card .iconP {

  padding-top: 1rem;
  align-content: center;
  width: 22rem;
  height: 20rem;


}

.container .card .iconB {

  padding-top: 1rem;
  align-content: center;
  width: 22.5rem;
  height: 20rem;

}

.container .card .iconE {

  padding-top: 1rem;
  align-content: center;
  width: 22.5rem;
  height: 20rem;

}

.container .card .slide {
  width: 22.5rem;
  height: 16.2rem;

}

.image{
  animation: pulse 2s linear;
  width:100%;

}

 @keyframes pulse {
    0% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
    }
    25% {
      -webkit-transform: scale(0.4);
      transform: scale(0.4);
  }
    50% {
       -webkit-transform: scale(0.8);
       transform: scale(0.8) ;
    
    }
    100% {
        -webkit-transform: scale(1.0);
       transform: scale(1.0);

    }
  }


.tablet{
  display: none;
 }


 .mobile{
  display: block;
 }

 }

 @media only screen and (max-width: 376px) {



  .about1{
    padding: 5px;
    text-align: center;
  }

  .PageT{
  margin: 0;
}

  .image11{
    display: block;

  }

  .PageT2{
    display: none;
    position: relative;
    margin: 0 0 5px 0;
    }

    .PageT3{
      margin: 0;
    }



  .div2{
    border-radius: 3px;
    box-shadow: none;
    -webkit-box-shadow:  0;
    -moz-box-shadow:  0;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    margin-bottom: 0.5rem;
    background-image: none;
    width: fit-content;
    height: fit-content;
  }

  .intro{
    margin: 2px;
  }

  .img1{
    box-shadow: 0 4px 10px rgba(188, 189, 188, 0.332);
    margin-left: 1px;
    margin-right: 1px;
    margin-bottom: 4px;
    padding: 0px;
  }

  .glow {
    margin: 0px;
    padding: 5px;
    width:100%;
   margin-bottom: 2rem;
   margin-left: none;
  }

  .div1{
    margin-bottom: 20px;
    margin: 0;
    padding: 0;
    font-size: 18px;
  }

  .div1:hover{
    margin-top: 6px;
  }
  
  .cards1{

    margin: 0px;
    margin-top: 5px;
    margin-bottom: 1rem;
    padding: 4px;
    width: 100%;

  }

  .card1 {
    border-radius: 3px;
    padding: 8px;
    background-color:  rgba(32, 32, 32, 0.492);  
    -moz-box-shadow: rgba(32, 32, 32, 0.492);
    -webkit-box-shadow: rgba(32, 32, 32, 0.492);
    box-shadow:  1px 1px 1px rgb(255, 255, 255), 0 0 7px #1eee60, 0 0 10px #1eee60;
    width: 97%;
    margin-left: 2px;
    margin-right: 0px;
  }



  .img2{
    width: 98%;
  }

  .box{
    margin:2px;
    margin-bottom: 2rem;
    padding: 5px;
    width: 99%;
  }

.box1{
  width: 90%;
}

  .box2{
    width: 100%;
    margin: 3px;
    padding: 3px;
    margin-bottom: 1rem;

  }
  
  .box4{
    margin-left: 7px;
    margin-right: 0px;
    width: 94%;
  }

.intro2 {
  margin: 0px;
  margin-bottom: 0.4rem;
  width: 100%;
  padding: 2px;
}


button:hover {
  text-shadow: 0 10px 20px rgba(168, 255, 38, 0.611);
  padding: 10px 30px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  font-size: medium;
  cursor: crosshair;
  color: #ececec;
  -moz-animation: none;
  -webkit-animation: none;
  animation: none;
  box-shadow: 0px 1px 6px #1eee60;
  -webkit-box-shadow: 0px 1px 6px #1eee60;
  -moz-box-shadow: 0px 1px 6px #1eee60;

}

.container .card .iconP {

  padding-top: 1rem;
  align-content: center;
  width: 21.9rem;
  height: 20rem;


}

.container .card .iconB {

  padding-top: 1rem;
  align-content: center;
  width: 21.9rem;
  height: 20rem;

}

.container .card .iconE {

  padding-top: 1rem;
  align-content: center;
  width: 21.9rem;
  height: 20rem;

}
.container .card .slide {
  width: 21.9rem;
  height: 16.2rem;

}



.tablet{
  display: none;
 }


 .mobile{
  display: block;
 }
}

@media only screen and (max-width: 344px) {

  .intro2 {
    margin: 2px;
  }

  .image11{
    display: block;
  }

  .PageT2{
    display: none;
    position: relative;
    margin: 0 0 5px 0;
    }

  .div2{
    border-radius: 3px;
    box-shadow: none;
    -webkit-box-shadow:  0;
    -moz-box-shadow:  0;
    margin-left: 0px;
    margin-right: 0px;
    padding: 0px;
    margin-bottom: 0.5rem;
    background-image: none;
    width: fit-content;
    height: fit-content;
  }


  .card5{
display: inline-block;
    width: 100%;
      }

  .box{
    padding: 2px;
    margin: 2px;
        margin-bottom: 2rem;
  }

  .box4{
    margin-left: 10px;
    margin-right: 0;
    width: 93%;
  }

  .box5{
    margin-left: 0px;
    margin-right: 0px;
    padding: 3px;
    width: 100%;
  }

  .container .card .iconP {

    padding-top: 1rem;
    align-content: center;
    width: 20rem;
    height: 20rem;

  }
  
  .container .card .iconB {
  
    padding-top: 1rem;
    align-content: center;
    width: 20rem;
    height: 20rem;
  
  }
  
  .container .card .iconE {
  
    padding-top: 1rem;
    align-content: center;
    width: 20rem;
    height: 20rem;
  
  }

  .container .card .slide {
    width: 20rem;
    height: 16.2rem;
    transition: 0.5s;
background-color: #0a0a0a;

}

  .glow {
    width: 99%;
  }

  .div1{
    width: 20rem;
     font-size: 17px;
  }

  .img2{
    width: 98%;
    margin-bottom: 6px;
  }

  .tablet{
    display: none;
   }
  
  
   .mobile{
    display: block;
   }

}